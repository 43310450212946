// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Rails from '@rails/ujs';
import * as Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start();
Turbolinks.start();
ActiveStorage.start();

//  import 'bootstrap/js/src/alert'
//  import 'bootstrap/js/src/button'
//  import 'bootstrap/js/src/carousel'
//  import 'bootstrap/js/src/collapse'
//  import 'bootstrap/js/src/dropdown'
//  import 'bootstrap/js/src/modal'
//  import 'bootstrap/js/src/popover'
//  import 'bootstrap/js/src/scrollspy'
//  import 'bootstrap/js/src/tab'
//  import Toast from 'bootstrap/js/dist/toast';
//  import 'bootstrap/js/src/tooltip'

//import * as bootstrap from 'bootstrap';

// interface RoutesIf {
//   // Names:
//   current_league_name_path(
//     league: string,
//     options: Record<string, unknown>
//   ): string;
//   league_name_path(league: string, spelling: string): string;
//   rate_league_name_path(league: string, spelling: string): string;
//
//   // Ratings:
//   rating_path(rating_id: number): string;
// }

// eslint-disable-next-line
// const Routes = require('routes.js.erb') as RoutesIf;

function installClientSelectorEventHandler() {
  const s = document.querySelector("select[data-client-selector='true']");
  s.addEventListener('change', (event: Event) => {
    console.log(event);
    const client = (event.target as HTMLInputElement).value;
    const path = window.location.pathname;
    const new_path = path.replace(
      /^(\/clients\/[^/]*)?\//,
      client ? `/clients/${client}/` : '/'
    );
    Turbolinks.visit(new_path);
  });
}

window.addEventListener('load', installClientSelectorEventHandler);
window.addEventListener('turbolinks:load', installClientSelectorEventHandler);

console.log('Hello World from Webpacker');
